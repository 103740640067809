
<template>
  <b-container class="pay-invoices">
    <div v-if="!invoices.length && !loading">
      <h5 class="pb-4">No payable invoices selected</h5>
      <b-link
        variant="link"
        aria-label="Go Back Link"
        @click="goBack"
      >
        <fa-icon icon="chevron-left" />
        Back
      </b-link>
    </div>
    <div v-else>
      <div class="title py-2 px-4">Invoice Payment</div>
      <hr>
      <div class="p-4">
        <b-row>
          <b-col cols="12" lg="7">
            <payment-method-options
              :total="grandTotal"
              :is-show-actions="false"
              :is-show-pay-agreement="false"
            />
          </b-col>
          <b-col cols="12" lg="5">
            <div class="w-100">
              <div class="d-flex px-2">
                <b-checkbox v-if="ownerInvoices" v-model="setupAsAutopay">Setup as Autopay</b-checkbox>
                <b-checkbox v-if="showAdminTools" v-model="manualRemittance" class="ml-auto">
                  Manual Remittance
                </b-checkbox>
              </div>
              <div class="summary-container mt-4 pt-3">
                <div class="summary-title px-4 pb-3">Payment Summary</div>
                <hr class="my-0">
                <div class="px-4">
                  <b-list-group v-for="invoice in invoices" :key="invoice.id" class="pt-3">
                    <b-list-group-item class="d-flex justify-content-between" href="#" target="_blank" @click="toggleCollapseFor(invoice.id)">
                      <div>
                        <div>
                          {{ invoice.company_name }}
                        </div>
                        <div class="invoice-details-text">{{ invoice.invoice_number }}</div>
                      </div>
                      <span>{{ invoice.total | currency }}</span>
                    </b-list-group-item>
                    <b-collapse :id="collapseIdFor(invoice.id)" class="pt-1">
                      <table class="w-100 details-table">
                        <tr>
                          <th class="pl-2">Description</th>
                          <th class="text-center">Count</th>
                          <th class="text-right">Price</th>
                        </tr>
                        <tr v-for="lineItem in invoice.invoice_line_items" :key="lineItem.id">
                          <td class="d-flex pl-2">
                            <div>&#x2022;</div>
                            <div class="ml-1 pr-2">{{ lineItem.description }}</div>
                          </td>
                          <td class="text-center">{{ lineItem.quantity }}</td>
                          <td class="text-right">{{ lineItem.price | currency }}</td>
                        </tr>
                      </table>
                    </b-collapse>
                  </b-list-group>
                </div>
                <div class="px-4">
                  <hr>
                </div>
                <div class="d-flex px-4 pb-2">
                  <strong>Total</strong>
                  <strong class="ml-auto">{{ grandTotal | currency }}</strong>
                </div>
                <hr class="my-4">
                <div class="px-4 pb-4">
                  <b-form-checkbox
                    v-model="tosAccepted"
                    :disabled="loading"
                    name="TOS Checkbox"
                  >
                    <span>
                      I accept the <a class="link-primary" :href="newTosUrl" target="_blank">Terms and Conditions</a>
                    </span>
                  </b-form-checkbox>
                </div>
                <div class="px-4 pb-4 d-flex">
                  <payment-ssl class="ssl-badge" />
                  <b-link
                    class="py-0 my-auto ml-auto"
                    variant="link"
                    aria-label="Go Back Link"
                    @click="goBack"
                  >
                    <fa-icon icon="chevron-left" />
                    Back
                  </b-link>
                  <b-button
                    class="ml-4"
                    variant="primary"
                    :disabled="!canSubmit"
                    :loading="loading"
                    aria-label="Submit Payment Button"
                    @click="submit"
                  >
                    Submit
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <ct-centered-spinner v-if="loading">
      Processing Payment
    </ct-centered-spinner>
  </b-container>
</template>

<script>
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'
import { mapActions, mapGetters } from 'vuex'
import PaymentSsl from '@/components/shared/PaymentSsl'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export default {
  name: 'PayInvoices',
  components: {
    CtCenteredSpinner,
    PaymentSsl,
    PaymentMethodOptions,
  },
  mixins: [makeToastMixin],
  props: {
    invoiceIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      invoices: [],
      setupAsAutopay: false,
      manualRemittance: false,
      tosAccepted: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('invoices', ['findAll']),
    ...mapGetters('website', ['newTosUrl']),
    ...mapGetters('stageline', ['showAdminTools']),
    ...mapGetters('paymentMethods', ['selectedPaymentMethod']),
    grandTotal(){
      return this.invoices.reduce((total, invoice) => total += invoice.total, 0)
    },
    canSubmit(){
      return this.selectedPaymentMethod?.id && this.tosAccepted && !this.loading
    },
    ownerInvoices(){
      return this.invoices.every(invoice => invoice.owned)
    },
  },
  async mounted() {
    this.loading = true
    await this.loadIfNotAvailableById({ ids: this.invoiceIds })
    this.invoices = this.findAll(this.invoiceIds)
    this.loading = false
  },
  methods: {
    ...mapActions('invoices', ['loadIfNotAvailableById']),
    ...mapActions('services', ['getAndReloadServices']),
    collapseIdFor(invoiceId) {
      return `v-b-toggle.${invoiceId}`
    },
    toggleCollapseFor(invoiceId) {
      this.$root.$emit('bv::toggle::collapse', this.collapseIdFor(invoiceId))
    },
    async submit(){
      this.loading = true
      try {
        const serviceIds = this.setupAsAutopay ? await this.getServiceIdsWithoutAutopay() : []
        const response = await this.$store.dispatch('invoices/payInvoices', {
          ids: this.invoiceIds,
          payableId: this.selectedPaymentMethod.id,
          manualRemittance: this.manualRemittance,
          setupAsAutopay: this.setupAsAutopay,
        })

        if (response.success) {
          this.logAutopaySetupInteraction(serviceIds)
          await this.$store.dispatch('dashpanel/refresh')
          this.goBack()
          this.successToast('Success', 'Payment completed successfully')
        } else {
          this.errorToast('Error', response.data.error.message)
        }
      } catch {
        this.errorToast('Error', 'There was an error submitting the payment')
      }
      this.loading = false
    },
    goBack(){
      this.$router.go(-1)
    },
    logAutopaySetupInteraction(serviceIds) {
      if (!this.setupAsAutopay) return

      try {
        const interaction = {
          name: 'pay-invoices-page',
          action: 'setup-autopay-checked',
          service_ids: serviceIds,
        }

        createOrFindClientInteractionLog({
          category: 'autopay-interaction',
          subCategory: 'setup-autopay',
          interaction: interaction,
          incompleteLogFromToday: true,
        }).catch()
      } catch {
        // suppress any errors
      }
    },
    async getServiceIdsWithoutAutopay() {
      const ids = [
        ...new Set(this.invoices.flatMap(inv => inv.invoice_line_items
          .filter(ili => ili.object_table === 'services')
          .map(sli => sli.object_id))),
      ]
      try {
        const { result } = await this.getAndReloadServices({ params: {}, ids })
        return result.filter(svc => !svc.autopay_payable_id).map(svc => svc.id)
      } catch {
        return [] // fail silently for client interaction log
      }
    },
  },
}
</script>


<style lang="scss">
.container{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ct-centered-spinner-component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.pay-invoices {
  max-width: 1500px !important;

  hr {
    border-top: 1px solid #BDBDBD;
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
  }

  .summary-container {
    width: 100%;
    border: 1px solid #98999B;
    border-radius: 8px;

    .summary-title {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .invoice-details-text {
      font-weight: 400;
    }

    .list-group-item {
      padding: 0;
      border: none;
    }
    .list-group-item-action {
      color: black !important;
    }

    .details-table{
      th {
        font-weight: 600;
        font-size: .9rem;
      }
      td {
        font-weight: 400;
        font-size: .9rem;
      }
    }
  }
}
</style>
